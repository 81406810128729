import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql, Link } from "gatsby"
import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"
import moment from "moment"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import { NavTabs, TabPane } from "../../../components/ak-tabs/tabs"

import "../../../graphql/fragments"

import { Breadcrumbs } from "../../../components/breadcrumbs/breadcrumbs"
import { CoverMain } from "../../../components/cover-main/cover-main"
import { HeadingBasic } from "../../../components/heading/heading"
import { Container } from "../../../components/grid/grid"
import {
  WpGroup,
  WpIconlist,
  Stats,
  Carousel,
} from "../../../components/utils/render-functions"
import Document from "../../../components/document/document"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const groupBy = (array, key) => {
  if (array) {
    return array.reduce((result, currentValue) => {
      ;(result[currentValue["node"]["acfFile"][key]] =
        result[currentValue["node"]["acfFile"][key]] || []).push({
        title: currentValue.node.title || "Title",
        linkTo:
          currentValue.node.acfFile.file !== null &&
          currentValue.node.acfFile.file.mediaItemUrl,
        fileSize:
          currentValue.node.acfFile.file !== null &&
          currentValue.node.acfFile.file.filesize,
        fileId:
          currentValue.node.acfFile.file != null &&
          currentValue.node.acfFile.file.databaseId,
        date: `${moment(currentValue.node.date).format(
          "DD MMMM YYYY"
        )} • ${moment(currentValue.node.date).format("HH:mm")}`,
      })
      return result
    }, {})
  }
}

const Report = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      imgCoverMain: file(relativePath: { eq: "imgBannerDevelpoment.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wordPress {
        pageBy(uri: "/sustainability/report") {
          id
          title
          slug
          blocks {
            ...CoreParagraphBlock
            ...CoreHeadingBlock
            ...CoreCoverBlock
            ...AcfGalleryBlock
            ...CoreGroupBlock
            ...AcfIconListBlock
            ...CoreEmbedYoutube
          }
          childPages {
            nodes {
              title
              slug
              excerpt(format: RAW)
              featuredImage {
                sourceUrl
                title
                caption
              }
            }
          }
          seo {
            title
            twitterDescription
            metaDesc
            opengraphDescription
          }
          translation(language: ID) {
            title
            blocks {
              ...CoreParagraphBlock
              ...CoreHeadingBlock
              ...CoreCoverBlock
              ...AcfGalleryBlock
              ...CoreGroupBlock
              ...AcfIconListBlock
              ...CoreEmbedYoutube
            }
            seo {
              title
              twitterDescription
              metaDesc
              opengraphDescription
            }
          }
        }
      }
    }
  `)

  const { data: reports } = useQuery(gql`
    query GetFiles {
      fileCategory(id: "sustainability", idType: SLUG) {
        slug
        filesPdf(first: 30) {
          edges {
            cursor
            node {
              title
              date
              acfFile {
                company
                year
                file {
                  databaseId
                  guid
                  mediaItemUrl
                  filesize
                  date
                }
              }
            }
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
        }
      }
    }
  `)

  const wordPress =
    data?.wordPress?.pageBy?.translation?.blocks ||
    data?.wordPress?.pageBy?.blocks
  const seo = data.wordPress.pageBy.seo
  const cover =
    wordPress &&
    wordPress.find(x => x.name === "core/cover") === wordPress[0] &&
    wordPress[0]

  const groupByCompany =
    reports &&
    reports.fileCategory.filesPdf &&
    groupBy(reports.fileCategory.filesPdf.edges, "company")

  let reportsData = []

  reports &&
    reports.fileCategory.filesPdf &&
    reports.fileCategory.filesPdf.edges.map((item, i) => {
      reportsData.push({
        title: item.node.title || "Title",
        linkTo:
          item.node.acfFile.file !== null &&
          item.node.acfFile.file.mediaItemUrl,
        fileSize:
          item.node.acfFile.file !== null && item.node.acfFile.file.filesize,
        fileId:
          item.node.acfFile.file != null && item.node.acfFile.file.databaseId,
        date: `${moment(item.node.date).format("DD MMMM YYYY")} • ${moment(
          item.node.date
        ).format("HH:mm")}`,
      })
    })

  return (
    <Layout location={location} currentLocation={location.pathname} lang="ID">
      <SEO
        title={seo.title}
        description={seo.twitterdescriot}
        img={
          cover ? cover.attributes.url : data.imgCoverMain.childImageSharp.fluid
        }
        url={location?.href}
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>
      {cover && (
        <CoverMain
          h1={
            data?.wordPress?.pageBy?.translation?.title ||
            data?.wordPress?.pageBy?.title
          }
          img={
            cover
              ? cover.attributes.url
              : data.imgCoverMain.childImageSharp.fluid
          }
          alt="Report"
          className="cover-md"
        >
          <Breadcrumbs path={location.pathname} className="breadcrumb-light" />
        </CoverMain>
      )}
      {wordPress?.map((item, i) => {
        switch (item.name) {
          case "core/heading":
            return (
              <div key={`heading-${i}`} className="container">
                <div className={i === 0 ? "py-main" : "pt-main"}>
                  <HeadingBasic h2={item.attributes.content} />
                </div>
              </div>
            )
          case "core/buttons":
            if (
              item.innerBlocks[0].attributes.url &&
              item.innerBlocks[0].attributes.url.split(".").length > 1
            ) {
              return (
                <div key={`button=${i}`} className="container">
                  <div className={i === 0 ? "py-main" : "pb-main"}>
                    <HeadingBasic>
                      <a
                        href={item.innerBlocks[0].attributes.url}
                        target="_blank"
                        className="btn btn-link fx-underline"
                        rel="noopener noreferrer"
                        key={`${item.innerBlocks[0].attributes.url}-${i}`}
                      >
                        {item.innerBlocks[0].attributes.text} &nbsp;
                        <i className="far fa-external-link"></i>
                      </a>
                    </HeadingBasic>
                  </div>
                </div>
              )
            } else {
              return (
                <div key={`button-${i}`} className="container">
                  <Link
                    key={`${item.innerBlocks[0].attributes.url}-${i}`}
                    to={item.innerBlocks[0].attributes.url}
                    className="btn btn-link fx-underline"
                  >
                    {item.innerBlocks[0].attributes.text}
                  </Link>
                </div>
              )
            }
          case "core/paragraph":
            return (
              <div key={`headingbasic-${i}`} className="py-main">
                <div className="container container-md">
                  <HeadingBasic text={[item.attributes.content]} />
                </div>
              </div>
            )
          case "core/group":
            return (
              <div key={`${item.name}-${i}`} className="py-main">
                <WpGroup data={item} />
              </div>
            )
          case "acf/iconlist":
            return (
              <div
                key={`iconlist-${i}`}
                className={i === 0 ? "container py-main" : "container pb-main"}
              >
                <WpIconlist data={item} />
              </div>
            )
          case "acf/labelvalues":
            return (
              <div key={`${item.name}-${i}`} className="pb-main">
                <Stats data={item} />
              </div>
            )
          case "acf/acfgallery":
            return (
              <div key={`gallery-${i}`} className="container">
                <Carousel data={item} />
              </div>
            )
          default:
            return <div key={`default-${i}`}></div>
        }
      })}
      <section className="pb-main">
        <Container>
          <NavTabs
            scrollable
            className="pt-4"
            data={[
              {
                name: "Sinarmas Land Limited",
                target: "sinarmasland",
              },
              { name: "PT Bumi Serpong Damai Tbk", target: "bsd" },
              {
                name: "PT Duta Pertiwi Tbk",
                target: "dutaPertiwi",
              },
            ]}
          />
        </Container>
        <TabPane id="sinarmasland" className="py-lg-5 pt-5 pt-lg-0 active">
          <Document
            data={groupByCompany && groupByCompany["Sinarmas Land Limited"]}
            type="sustainability"
          />
        </TabPane>
        <TabPane id="bsd" className="py-lg-5 pt-5 pt-lg-0">
          <Document
            data={groupByCompany && groupByCompany["PT Bumi Serpong Damai Tbk"]}
            type="sustainability"
          />
        </TabPane>
        <TabPane id="dutaPertiwi" className="py-lg-5 pt-5 pt-lg-0">
          <Document
            data={groupByCompany && groupByCompany["PT Duta Pertiwi Tbk"]}
            type="sustainability"
          />
        </TabPane>
      </section>
    </Layout>
  )
}

export default Report
